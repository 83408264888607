html, body {
  width: 100%;
  height: 100%;
}

:is(input::-ms-clear, input::-ms-reveal) {
  display: none;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

@-ms-viewport {
  width: device-width;
}

body {
  margin: 0;
}

[tabindex="-1"]:focus {
  outline: none;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5em;
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title], abbr[data-original-title] {
  -webkit-text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1em;
}

input[type="text"], input[type="password"], input[type="number"], textarea {
  -webkit-appearance: none;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

a, area, button, [role="button"], input:not([type="range"]), label, select, summary, textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  text-align: left;
  caption-side: bottom;
  padding-top: .75em;
  padding-bottom: .3em;
}

input, button, select, optgroup, textarea {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
  width: 100%;
  max-width: 100%;
  margin-bottom: .5em;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

:is([type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

:is([type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration) {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  background-color: #feffe6;
  padding: .2em;
}

@font-face {
  font-family: equiplight;
  src: url("https://static.ring.com/fonts/Equip/Light/Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: equipmedium;
  src: url("https://static.ring.com/fonts/Equip/Medium/Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: equipextralight;
  src: url("https://static.ring.com/fonts/EquipW03Extra_Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/*# sourceMappingURL=index.2723ab3a.css.map */

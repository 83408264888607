@font-face {
    font-family: 'equiplight';
    src: url('https://static.ring.com/fonts/Equip/Light/Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'equipmedium';
    src: url('https://static.ring.com/fonts/Equip/Medium/Medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: 'equipextralight';
    src: url('https://static.ring.com/fonts/EquipW03Extra_Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}